<template>
  <div>
    <h2>{{ $parent.title }} | Создание</h2>
    <div class="success" v-if="status == 201">{{ $parent.title }} | Успешно создан</div>
    <div class="errors" v-if="status == 422">
      <div v-for="error in errors" :key="error">{{ error[0] }}</div>
    </div>
    <input type="text" v-model="name" placeholder="ФИО пользователя:">
    <input type="text" v-model="login" placeholder="Логин пользователя:">
    <input type="password" v-model="password" placeholder="Пароль пользователя:">
    <select v-model="role">
      <option value="">Не выбран уровень доступа</option>
      <option value="timetable">Управление расписанием</option>
    </select>
    <select v-model="campus_id">
      <option value="">Не выбран учебный корпус</option>
      <option v-for="campus in campuses" :value='campus.id' :key="campus.id">{{ campus.name }}</option>
    </select>
    <button @click="create()">Создать</button>
  </div>
</template>

<script>
import {response} from "../../response";

export default {
  name: "Create",
  data() {
    return {
      name: '',
      login: '',
      password: '',
      role: '',
      campus_id: '',
      campuses: [],
      status: -1
    }
  },
  async mounted() {
    let res = await response(this.$parent.url + '/campuses');
    if(res.status == 403)
      return this.$router.push({name: "Home"})
    this.campuses = res.json
  },
  methods: {
    async create() {
      let data = {
        method: "POST",
        body: JSON.stringify(this.$data)
      }
      let res = await response(`${this.$parent.url}/create`, data);
      this.status = res.status;
      if(res.status != 201) this.errors = res.json.errors
      else this.name = ''
    }
  }
}
</script>

<style scoped>
  .errors {
    color: #eb4444;
    text-align: center;
    font-weight: 400;
    margin: 5px 0;
  }
  .success {
    color: green;
    font-weight: 500;
    margin: 10px 0;
    text-align: center;
  }
  input, select {
    width: 60%;
    margin: 5px auto;
    display: block;
    padding: 5px 10px;
    border-radius: 7px;
    border: thin solid #606060;
    font-size: 16px;
    box-sizing: border-box;
  }
  button {
    width: 200px;
    display: block;
    margin: 10px auto;
    border: none;
    padding: 10px 15px;
    border-radius: 10px;
    background-color: #4b77e4;
    font-weight: bold;
    color: white;
    font-size: 16px;
  }
</style>