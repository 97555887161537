<template>
  <section class="menu" v-if="$route.name != 'Login'">
    <menu>
      <div class="user">
        <div class="fullname">{{ global_name }}</div>
        <div class="organization">{{ global_organization}}</div>
        <div class="plans">План доступа: Колледж (полный)</div>
      </div>
      <router-link v-if="global_access == 'creator'" to="/teachers">Преподаватели</router-link>
      <router-link v-if="global_access == 'creator'" to="/disciplines">Дисциплины</router-link>
      <router-link v-if="global_access == 'creator'" to="/campus">Учебные корпуса</router-link>
      <router-link v-if="global_access == 'creator'" to="/users">Пользователи</router-link>
      <router-link v-if="global_access == 'creator'" to="/lesson-type">Типы уроков</router-link>

      <router-link v-if="global_access == 'timetable'" to="/timetables">Расписание</router-link>
      <router-link v-if="global_access == 'timetable'" to="/hourly-report">Отчет по часам</router-link>

      <router-link to="/logout">Выход</router-link>
    </menu>
  </section>
  <router-view/>
  <section class="download" id="download"></section>
  <section class="information" v-if="msg.length">
    <div class="destroyAll" @click="destroyMSGAll()">Удалить все уведомления</div>
    <div class="maxHeight">
      <div class="msg" v-for="item in msg" :class="[item.status]" :key="item">{{ item.message }}</div>
    </div>
  </section>
</template>

<style lang="less">
@media screen {
  body {
    padding: 0;
    margin: 0;
    min-height: 100vh;
    max-width: 100%;
    width: 100%;
    background: #CFD8DC;

    #app {
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: space-around;
      align-items: self-start;
      flex-wrap: wrap;
      max-width: 100%;
      width: 100%;
    }

    .operation {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .edit, .destroy {
        font-size: 12px;
        cursor: pointer;
        line-height: 20px;
        border: thin solid #eb4444;
        color: #eb4444;
        padding: 2px 5px;
        border-radius: 3px;
        text-align: center;
      }
    }

    section {
      &.object {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
        align-items: self-start;
      }

      &.menu {
        width: 100%;
        margin: 20px;

        menu {
          box-sizing: border-box;
          max-width: 960px;
          width: 100%;
          margin: 0 auto;
          background-color: white;
          border-radius: 15px;
          min-height: 75px;
          box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.2);
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;

          a {
            display: block;
            color: black;
            font-size: 16px;
            font-weight: 300;
          }

          .user {
            align-self: stretch;
            border-right: thin solid #78909c;
            box-sizing: border-box;
            padding: 10px;
            display: flex;
            justify-content: center;
            flex-direction: column;

            .organization {
              font-size: 10px;
              font-weight: 400;
            }

            .plans {
              font-size: 10px;
              font-weight: bold;
            }
          }
        }
      }

      &.download {
        display: none;
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9998;
        background-color: rgba(0, 0, 0, 0.6);
      }

      &.information {
        position: fixed;
        bottom: 5px;
        left: 5px;
        z-index: 9999;

        .destroyAll {
          font-size: 12px;
          text-align: center;
          width: 300px;
          background: #333333;
          color: white;
          border-radius: 3px;
          padding: 2px 0;
          cursor: pointer;
        }

        .maxHeight {
          max-height: 350px;
          overflow: auto;

          .msg {
            margin-top: 5px;
            width: 300px;
            font-size: 14px;
            border: thin solid black;
            border-radius: 3px;
            background: white;
            box-sizing: border-box;
            padding: 5px 10px;
            font-weight: bold;

            &.error {
              background-color: #ff9393;
            }

            &.success {
              background-color: #a1ffa1;
            }
          }
        }
      }

      &.print {
        display: none;
      }
    }
  }
}
@media print {
  body {
    background: white;
    #app {
      background: white;
    }
  }
  section { display: none; }
  section.object {
    display: block;
  }
}
</style>

<script>
import { response } from './response'
export default {
  name: "Index",
  data() {
    return {
      global_name: "",
      global_access: 'none',
      global_organization: "",
      user: null,
      msg: [],
      print: null
    }
  },
  async mounted() {
    document.title = 'Главная страница'
    await this.load();
  },
  methods: {
    destroyMSGAll() {
      this.msg = [];
    },
    async load() {
      let res = await response('user-data')
      if(res.status == 401) {
        localStorage.clear();
        return this.$router.push({name: "Login"})
      }
      this.user = res.json
      this.global_name = res.json.name
      this.global_access = res.json.access
      this.global_organization = res.json.organization.name
      this.global_organization = res.json.organization.name
    },
    addMSG(text, status) {
        this.msg.push({
          status: status,
          message: text
        });
    }
  }
}
</script>
