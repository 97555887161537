<template>
  <h1>{{ $parent.title }} | Весь список</h1>
  <div class="block_get_data">
    <div class="block_item" v-for="item in getData" :key="item.id">
      <div class="name" @dblclick="edit(item)">
        <div v-if="!item.edit">{{ item.name }}</div>
        <input v-if="item.edit" type="text" v-model="item.name" @keyup.enter="editSend(item)">
      </div>
      <div class="function"><a :href="`/campus/${item.id}`"><i class="bi bi-list"></i> Учебные года</a></div>
      <div class="operation">
        <div class="destroy" @click="getDestroy = item"><i class="bi bi-trash"></i> Удалить</div>
      </div>
    </div>
  </div>
  <Destroy :item="getDestroy" :url="$parent.url" v-if="getDestroy"></Destroy>
</template>

<script>
import {response} from "./../../response"
import Destroy from "./Destroy";
export default {
  name: "Show",
  components: {
    Destroy
  },
  data() {
    return {
      getData: {},
      getDestroy: null
    }
  },
  async mounted() {
    await this.load();
  },
  methods: {
    edit(item) {
      item.edit = !item.edit;
    },
    async editSend(item) {
      let data = {
        method: "POST",
        body: JSON.stringify(item)
      }

      let res = await response(`${this.$parent.url}/edit/${item.id}`, data)
      item.edit = false;
      if(res.status == 202) alert('Операция успешно выполнена!');
    },
    async load() {
      let res = await response(this.$parent.url);
      if(res.status == 403)
        return this.$router.push({name: "Home"})
      this.getData = res.json
    }
  }
}
</script>

<style lang="less" scoped>
.block_get_data {
  position: relative;
  max-height: 450px;
  overflow: auto;
  .block_item {
    display: flex;
    justify-content: space-between;
    border: thin solid #78909c;
    border-radius: 6px;
    margin-top: 5px;
    box-sizing: border-box;
    padding: 5px 10px;
    align-items: center;

    .name {
      color: black;
      font-size: 16px;
      font-weight: 300;
      width: 200px;
    }

    .function {
      cursor: pointer;
      a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: black;
        font-size: 12px;
        border: thin solid black;
        border-radius: 5px;
        padding: 5px 10px;
        margin-left: 5px;
        display: inline-block;
        text-decoration: none;
      }
    }

    .operation {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .edit, .destroy {
        font-size: 12px;
        cursor: pointer;
        line-height: 20px;
        border: thin solid #eb4444;
        color: #eb4444;
        padding: 2px 5px;
        border-radius: 3px;
        text-align: center;
      }
    }
  }
}
</style>