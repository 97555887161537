<template>
  <div>
    <h2>Дисциплина: {{ item.name }}</h2>
    <div v-if="teachers">
      <div v-if="teachers.attached != []">
        <h5>Присоединенные к предмету</h5>
        <div class="scrolling">
          <div class="attached" @dblclick="destroy(t_item)" v-for="t_item in teachers.attached" :key="t_item">{{ t_item.teacher.name }}</div>
        </div>
        <hr>
      </div>
      <div v-if="teachers.notAttached != []">
        <h5>Не присоединенные к предмету</h5>
        <div class="scrolling">
          <select class="notAttached" @keyup.enter="add()" v-model="teacher">
            <option value="">Выберите педагога</option>
            <option :value="t_item" v-for="t_item in teachers.notAttached" :key="t_item">{{ t_item.name }}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {response} from "../../response";

export default {
  name: "Teachers",
  data() {
    return {
      teachers: null,
      teacher: ''
    }
  },
  props: ['item'],
  async mounted() {
    await this.load();
  },
  methods: {
    async add() {
      if(this.teacher === '') return this.$root.addMSG('Вы не выбрали педагога', 'error')
      let data = {
        method: "POST",
        body: JSON.stringify(this.teacher)
      }
      let res = await response(`discipline/teachers/${this.item.id}`, data);
      if(res.status == 201) this.$root.msg.push({
        status: 'success',
        message: `Преподаватель успешно добавлен!`
      });
      else this.$root.msg.push({
        status: 'error',
        message: `Ошибка выполнения операции!`
      });
      this.teacher = ''
      await this.load();
    },
    async destroy(teacherDiscipline) {
      let data = {
        method: "DELETE",
        body: JSON.stringify(teacherDiscipline)
      }
      let res = await response(`discipline/teachers/${this.item.id}`, data);
      if(res.status == 202) this.$root.msg.push({
        status: 'success',
        message: `Преподаватель успешно удален!`
      });
      else this.$root.msg.push({
        status: 'error',
        message: `Ошибка выполнения операции!`
      });
      await this.load();
    },
    async load() {
      let res = await response(`discipline/teachers/${this.item.id}`);
      this.teachers = res.json
    }
  }
}
</script>

<style scoped>
.attached {
  background: #4b77e4;
  color: white;
  font-size: 18px;
  font-weight: bold;
  border-radius: 5px;
  margin: 5px auto;
  padding: 5px 10px;
  box-sizing: border-box;
}
.notAttached {
  border: 1px solid #4b77e4;
  font-size: 18px;
  border-radius: 5px;
  margin: 5px auto;
  padding: 5px 10px;
  box-sizing: border-box;
  width: 100%;
}
.scrolling {
  max-height: 300px;
  overflow: auto;
}
</style>