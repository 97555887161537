<template>
  <section class="object">
    <div class="block">
      <h2>Учебные года</h2>
      <p>Выберите учебный год для работы с ним и составления расписания</p>
      <a :href="`/timetables/${item.id}`" v-for="item in getData" :key="item" class="year" >{{ item.name }}</a>
    </div>
  </section>
</template>

<script>
import {response} from "../response";
export default {
  name: "Timetables",
  data() {
    return {
      page: "show",
      url: 'academy-years',
      title: 'Учебные года',
      getData: null
    }
  },
  async mounted() {
    await this.load();
  },
  methods: {
    async load() {
      let res = await response(this.url)
      this.getData = res.json
    }
  }
}
</script>

<style lang="less" scoped>
.block {
  position: relative;
  max-width: 960px;
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 2px 9px 0 rgba(0,0,0,0.2);
  background-color: white;
  min-height: 300px;
  box-sizing: border-box;
  padding: 10px 15px;
  .year {
    border: thin solid #333;
    border-radius: 7px;
    padding: 10px 20px;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    color: black;
    &:hover {
      background-color: rgba(75, 119, 228, 0.8);
      color: white;
    }
  }
}
</style>