<template>
  <div>
    <h2>{{ $parent.title }} | Создание списком</h2>
    <div class="success" v-if="status == 201">{{ $parent.title}} | Успешно создано</div>
    <div class="errors" v-if="status == 422">{{ errors.name[0] }}</div>
    <input type="text" v-model="name" placeholder="Введите информацию:">
    <p class="small">Правила оформления записи списком: Объект;Объекта;Объект;</p>
    <p class="small">Используйте разделитель вида <b>;</b></p>
    <button @click="create()">Создать</button>
  </div>
</template>

<script>
import {response} from "./../../response"
export default {
  name: "CreateList",
  data() {
    return {
      name: '',
      academic_year_id: this.$route.params.id,
      errors: null,
      status: -1
    }
  },
  methods: {
    async create() {
      let data = {
        method: "POST",
        body: JSON.stringify(this.$data)
      }
      let res = await response(`${this.$parent.url}/create-list`, data);
      this.status = res.status;
      if(res.status != 201) this.errors = res.json.errors
      else this.name = ""
    }
  }
}
</script>

<style lang="less" scoped>
.errors {
  color: #eb4444;
  text-align: center;
  font-weight: 400;
  margin: 5px 0;
}
.success {
  color: green;
  font-weight: 500;
  margin: 10px 0;
  text-align: center;
}
.small {
  font-size: 12px;
  b {
    display: inline-block;
    vertical-align: middle;
    padding: 5px;
    background-color: #333;
    min-width: 20px;
    min-height: 20px;
    border-radius: 5px;
    text-align: center;
    color: white;
    font-weight: 600;
    font-size: 18px;
  }
}
input {
  width: 60%;
  margin: 0 auto;
  display: block;
  padding: 5px 10px;
  border-radius: 7px;
  border: thin solid #606060;
  font-size: 16px;
}
button {
  width: 200px;
  display: block;
  margin: 10px auto;
  border: none;
  padding: 10px 15px;
  border-radius: 10px;
  background-color: #4b77e4;
  font-weight: bold;
  color: white;
  font-size: 16px;
}
</style>