<template>
  <div class="item">
    <div class="number">{{ number }}</div>
    <div class="teacher">{{ disciplines[0] }}</div>
    <div class="cabinet">{{ disciplines[1] }}</div>
  </div>
</template>

<script>
export default {
  name: "scheduleItem",
  props: ['teacher', 'schedules', 'number'],
  computed: {
    disciplines() {

      // Создаем массив объектов расписания которые подошли по номеру пары и идентификатору дисциплины
      let array_schedules = this.schedules.filter(obj => obj.number === this.number && this.teacher.group_disciplines.includes(obj.group_discipline_id))

      // Создаем текст который будет выведен
      let teachers = null;
      let cabinets = null;
      array_schedules.forEach(el => {
        // Получаем имя группы
        let nameGroup = el.group_discipline.group.name
        let typeLesson = el.horly_load.lesson_type.name

        let teacher_context = nameGroup + " [" + typeLesson + "]"
        teachers = (teachers ? teachers + " / " + teacher_context : teacher_context)
        
        let cabinet_context = el.cabinet
        cabinets = (cabinets ? cabinets + " / " + cabinet_context : cabinet_context)
      })

      teachers = (teachers ? teachers : '')
      cabinets = (cabinets ? cabinets : '')
      return [teachers, cabinets];
    }
  }
}
</script>

<style lang="less" scoped>
  .item {
    border-bottom: thin solid #333;
    display: flex;
    min-height: 10px;
    text-align: center;
    .number {
      width: 10%;
      border-right: thin solid black;
    }
    .teacher {
      width:70%;
    }
    .cabinet {
      width: 20%;
      border-left: thin solid black;
    }
  }
</style>