<template>
  <div>
    <h2>{{ item.teacher }} / {{ item.discipline }}</h2>
    <div v-if='getData'>
      <h4>Подключенные типы пар</h4>
      <div class="attached">
        <div class="card" v-for="item in getData.attached" :key="item">
          <label>Тип урока</label>
          <div class="name">{{ item.lessonType.name }}</div>
          <label>Учет в расписании</label>
          <div class="name">{{ (item.lessonType.consider ? "В расписании" : "Вне расписания") }}</div>
          <label>Учет в сетке</label>
          <div class="name">{{ (item.lessonType.off_grid ? "Вне сетке" : "В сетки") }}</div>
          <label for="semester">Семестр</label>
          <select id="semester" v-model="item.semester" @change="change(item)">
            <option value="0">Не выбран семестр</option>
            <option value="1">1 семестр</option>
            <option value="2">2 семестр</option>
          </select>
          <label for="semester">Количество часов</label>
          <input type="number" v-model="item.amount" @keyup.enter="change(item)" placeholder="Количество часов в семестре">
          <p class="small">Для сохранения нажмите Enter</p>
        </div>
      </div>
      <hr>
      <h4>Типы пары</h4>
      <p class="small">Для добавления кликните 2 раза по нужному типу!</p>
      <div class="lessonType" v-for="item in getData.notAttached" :key="item" @dblclick="create(item)">
        <div class="name">{{ item.name }}</div>
        <div class="consider">{{ (item.consider ? "В расписании" : "Вне расписания") }}</div>
        <div class="off_grid">{{ (item.off_grid ? "Вне сетке" : "В сетки") }}</div>
      </div>
    </div>
    <div v-else>Данные загружаются</div>
  </div>
</template>

<script>
import {response} from "../../response";
export default {
  name: "Show",
  props: ['item'],
  data() {
    return {
      getData: null
    }
  },
  watch: {
    async item() {
      await this.load();
    }
  },
  async mounted() {
    await this.load()
  },
  methods: {
    async load() {
      let res = await response(this.$parent.url + '/discipline/' + this.item.id);
      if(res.status == 200) this.getData = res.json
    },
    async create(item) {
      let body = {
        lesson_type_id: item.id,
        group_discipline_id: this.item.id
      }
      let data = {
        method: "POST",
        body: JSON.stringify(body)
      }
      let res = await response(this.$parent.url + '/create', data)
      if(res.status == 201)
        this.$root.msg.push({
          status: 'success',
          message: `Успешно операция добавления выполнена!`
        });
      else
        this.$root.msg.push({
          status: 'error',
          message: `Ошибка выполнения операции`
        });
      await this.load()
    },
    async change(item) {
      let data = {
        method: "POST",
        body: JSON.stringify(item)
      }
      let res = await response(this.$parent.url + '/update/' + item.id, data)
      if(res.status == 202)
        this.$root.msg.push({
          status: 'success',
          message: `Данные успешно изменены!`
        });
      else
        this.$root.msg.push({
          status: 'error',
          message: `Ошибка выполнения операции`
        });
      await this.load()
    }
  }
}
</script>

<style lang="less" scoped>
  .lessonType {
    width: 80%;
    margin: 5px auto;
    padding: 5px 10px;
    border-radius: 5px;
    border: thin solid #333333;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    gap: 5px;
    .name {
      width: 200px;
    }
    .consider {
      font-size: 12px;
      background-color: #333;
      color: white;
      font-weight: bold;
      border-radius: 5px;
      padding: 3px 7px;
    }
    .off_grid {
      font-size: 12px;
      background-color: #235ff5;
      color: white;
      font-weight: bold;
      border-radius: 5px;
      padding: 3px 7px;
    }
  }
  .attached {
    width: 90%;
    margin: 10px auto;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    .card {
      width: 30%;
      border: thin solid #333;
      border-radius: 10px;
      padding: 10px;
      box-sizing: border-box;
      label {
        font-weight: 300;
        font-size: 12px;
      }
      .name {
        font-weight: bold;
        font-size: 14px;
      }
      select, input {
        font-size: 12px;
        font-weight: 300;
        border: thin solid #333;
        width: 100%;
        display: block;
        border-radius: 3px;
        padding: 2px 5px;
        box-sizing: border-box;
      }
    }
  }
  .small {
    font-size: 12px;
    padding: 0;
    margin: 0;
  }
</style>