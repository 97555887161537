<template>
  <div>
    <h2>{{ $parent.title }} | Создание</h2>
    <div class="success" v-if="status == 201">{{ $parent.title }} | Успешно создан</div>
    <div class="errors" v-if="status == 422">{{ errors.name[0] }}</div>
    <input type="text" v-model="name" placeholder="Введите информацию">
    <button @click="create()">Создать</button>
  </div>
</template>

<script>
import {response} from "./../../response"
export default {
  name: "Create",
  data() {
    return {
      name: '',
      errors: null,
      status: -1
    }
  },
  methods: {
    async create() {
      let data = {
        method: "POST",
        body: JSON.stringify(this.$data)
      }
      let res = await response(`${this.$parent.url}/create`, data);
      this.status = res.status;
      if(res.status != 201) this.errors = res.json.errors
      else this.name = ''
    }
  }
}
</script>

<style scoped>
  .errors {
    color: #eb4444;
    text-align: center;
    font-weight: 400;
    margin: 5px 0;
  }
  .success {
    color: green;
    font-weight: 500;
    margin: 10px 0;
    text-align: center;
  }
  input {
    width: 60%;
    margin: 0 auto;
    display: block;
    padding: 5px 10px;
    border-radius: 7px;
    border: thin solid #606060;
    font-size: 16px;
  }
  button {
    width: 200px;
    display: block;
    margin: 10px auto;
    border: none;
    padding: 10px 15px;
    border-radius: 10px;
    background-color: #4b77e4;
    font-weight: bold;
    color: white;
    font-size: 16px;
  }
</style>