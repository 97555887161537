<template>
  <section class="object">
    <div class="menu">
      <h2>Создание расписания</h2>
      <p class="small">Для создания нового дня расписания, нужно выбрать дату. После выбора даты нажмите кнопку Enter</p>
      <input type="date" v-model="date" @keyup.enter="createDate()">
    </div>

    <div class="block">
      <h2>Созданные даты</h2>
      <div v-if="getData">
        <a class="date" :href="`/timetables/${$route.params.year}/couples-date/${item.id}`" v-for="item in getData" :key="item"><div>{{ item.date }}</div><span class="week">{{ item.week }}</span></a>
      </div>
    </div>
  </section>
</template>

<script>
import {response} from "../response";

export default {
  name: "CouplesDates",
  data() {
    return {
      page: "show",
      url: 'campus',
      title: 'Учебные корпуса',
      date: '',
      getData: null
    }
  },
  async mounted() {
    await this.load();
  },
  methods: {
    async createDate() {
      let data = {
        method: "POST",
        body: JSON.stringify({
          date: this.date,
          year: this.$route.params.year
        })
      }
      let res = await response('couples-dates', data);
      if(res.status == 201) this.$root.addMSG('Дата успешно создана', 'success');
      else this.$root.addMSG('Ошибка создания данных', 'error');
      await this.load();

    },
    async load() {
      let res = await response(`couples-dates/${this.$route.params.year}`)
      this.getData = res.json
    }
  }
}
</script>

<style lang="less" scoped>
.menu {
  background-color: white;
  min-height: 300px;
  width: 320px;
  border-radius: 15px;
  box-shadow: 0 2px 9px 0 rgba(0,0,0,0.2);
  box-sizing: border-box;
  padding: 10px 15px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  a {
    color: black;
    font-size: 16px;
    margin-top: 10px;
  }
  .small {
    font-size: 12px;
  }
  input[type=date] {
    font-size: 22px;
    display: block;
    width: 80%;
    border-radius: 5px;
    border: thin solid #333;
    padding: 5px 10px;
    box-sizing: border-box;
    font-weight: 300;
    margin: 10px auto;
    font-family: Roboto;
  }
}
.block {
  position: relative;
  max-width: 620px;
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 2px 9px 0 rgba(0,0,0,0.2);
  background-color: white;
  min-height: 300px;
  box-sizing: border-box;
  padding: 10px 15px;

  .date {
    display: flex;
    gap: 5px;
    justify-content: space-between;
    border-radius: 5px;
    border: thin solid #333;
    font-size: 18px;
    font-weight: 300;
    color: black;
    line-height: 30px;
    text-decoration: none;
    width: 400px;
    margin: 5px auto;
    &:hover {
      background-color: #4b77e4;
      color: white;
    }
    div {
      padding: 5px 20px;
    }
    .week {
      background: #333;
      color: white;
      padding: 5px 20px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      display: inline-block;
      width: 120px;
    }
  }
}

hr {
  width: 100%;
  border: none;
  border-bottom: thin solid #ccc;
  margin-top: 20px;
  display: block;
}
</style>