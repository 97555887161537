<template>
  <section class="object">
    <div class="menu">
      <h2>Меню</h2>
      <a href="#" @click="page='show'">Все учебные года</a>
      <a href="#" @click="page='create'">Добавить учебный год</a>
    </div>

    <div class="block">
      <show v-if="page=='show'"></show>
      <create v-if="page=='create'"></create>
    </div>
  </section>
</template>

<script>
import Show from "../components/academicYears/Show";
import Create from "../components/academicYears/Create";

export default {
  name: "AcademicYears",
  data() {
    return {
      page: "show",
      url: 'academic-years',
      title: 'Учебные года корпусов'
    }
  },
  components: {
    Show,
    Create
  }
}
</script>

<style lang="less" scoped>
.menu {
  background-color: white;
  min-height: 300px;
  width: 320px;
  border-radius: 15px;
  box-shadow: 0 2px 9px 0 rgba(0,0,0,0.2);
  box-sizing: border-box;
  padding: 10px 15px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
a {
  color: black;
  font-size: 16px;
  margin-top: 10px;
}
}
.block {
  position: relative;
  max-width: 620px;
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 2px 9px 0 rgba(0,0,0,0.2);
  background-color: white;
  min-height: 300px;
  box-sizing: border-box;
  padding: 10px 15px;
}
</style>