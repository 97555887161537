<template>
  <div class="object">
    <h2>Расписание для группы: {{ item.name }}</h2>
    <p>Для разделения на подгруппы и на оборот дважды кликните на цифру.<br>
    <b>С</b> - семестр; <b>О</b> - остаток</p>
    <number-pair :group="item" :number="i" v-for="i in 8" :key="i"></number-pair>
  </div>
</template>

<script>
import numberPair from "./numberPair";
import {response} from "../../response";

export default {
  name: "itemSchedule",
  props: ['item'],
  data() {
    return {
      group_disciplines: null,
    }
  },
  watch: {
    async item() {
      await this.load();
    }
  },
  methods: {
    async load() {
      let res = await response(`group-disciplines/${this.item.id}`)
      if(res.status == 200) {
        this.group_disciplines = res.json
        this.$root.addMSG('Преподаватели группы успешно подгружены', 'success')
      }
      else this.$root.addMSG('Ошибка загрузки педагогов', 'error')
    }
  },
  components: {
    numberPair
  },
  async mounted() {
    await this.load();
  }
}
</script>

<style scoped>

</style>