<template>
  <div class="element">
    <div @dblclick="setTypePair()" class="number">
      <div>{{ number }}</div>
    </div>
    <div class="pair">
      <div v-if="subPair" class="subPair">
        <div class="sub">
          <h5 align="center">1 подгруппа</h5>
          <select v-model="whole[1].group_discipline_id">
            <option value="">Не выбран преподаватель</option>
            <option :value="item" v-for="item in $parent.group_disciplines" :key="item">{{ item.name }}</option>
          </select>
          <select v-model="whole[1].horly_load_id">
            <option value="">Тип пары</option>
            <option :value="item.id" v-for="item in whole[1].group_discipline_id.horly" :key="item">[С: {{item.semester}}] - {{ item.lessonType.name }} [О: {{ item.amount}}]</option>
          </select>
          <input type="text" v-model="whole[1].cabinet" placeholder="Номер кабинета">
          <analytics :analytics="whole[1].analytics" v-if="whole[1].analytics"></analytics>
        </div>
        <div class="sub">
          <h5 align="center">2 подгруппа</h5>
          <select v-model="whole[2].group_discipline_id">
            <option value="">Не выбран преподаватель</option>
            <option :value="item" v-for="item in $parent.group_disciplines" :key="item">{{ item.name }}</option>
          </select>
          <select v-model="whole[2].horly_load_id">
            <option value="">Тип пары</option>
            <option :value="item.id" v-for="item in whole[2].group_discipline_id.horly" :key="item">[С: {{item.semester}}] - {{ item.lessonType.name }} [О: {{ item.amount}}]</option>
          </select>
          <input type="text" v-model="whole[2].cabinet" placeholder="Номер кабинета">
          <analytics :analytics="whole[2].analytics" v-if="whole[2].analytics"></analytics>
        </div>
      </div>
      <div v-else>
        <h5 align="center">Вся группа</h5>
        <select v-model="whole[0].group_discipline_id">
          <option value="">Не выбран преподаватель</option>
          <option :value="item" v-for="item in $parent.group_disciplines" :key="item">{{ item.name }}</option>
        </select>
        <select v-model="whole[0].horly_load_id">
          <option value="">Тип пары</option>
          <option :value="item.id" v-for="item in whole[0].group_discipline_id.horly" :key="item">[С: {{item.semester}}] - {{ item.lessonType.name }} [О: {{ item.amount}}]</option>
        </select>
        <input type="text" v-model="whole[0].cabinet" placeholder="Номер кабинета">
        <analytics :analytics="whole[0].analytics" v-if="whole[0].analytics"></analytics>
      </div>
      <div class="save" @click="save()">Сохранить урок</div>
    </div>
  </div>
</template>

<script>
import {response} from "../../response";
import analytics from "./analytics";
export default {
  name: "numberPair",
  props: ['number', "group"],
  data() {
    return this.loadData()
  },
  watch: {
    async group() {
      Object.assign(this.$data, this.loadData())
      await this.load()
    },
    whole: {
      handler(val) {
        val.forEach(item => {
          if(item.group_discipline_id === '') {
            item.horly_load_id = ''
            item.cabinet = ''
          } else {
            let horly = item.group_discipline_id.horly.filter(obj => obj.id == item.horly_load_id)
            item.horly_load_id = (horly.length > 0 ? item.horly_load_id : '')
          }
        })
      },
      deep: true
    }
  },
  async mounted() {
    await this.load()
  },
  methods: {
    setTypePair() {
      this.subPair = !this.subPair
    },
    async save() {
      let data = {
        method: "POST",
        body: JSON.stringify(this.$data)
      }
      let res = await response('schedule/create', data)
      if(res.status == 201) this.$root.addMSG('Расписание на урок записано!', 'success');
      else this.$root.addMSG('Ошибка записи урока!', 'error');
      await this.$parent.load();
      await this.load();
    },
    async load() {
      Object.assign(this.$data, this.loadData())
      let res = await response(`timetables/${this.$route.params.day}/open/${this.group.id}?number=${this.number}`)
      if(res.status == 200) {
        res.json.forEach((item, idx) => {
          if(idx > 0 && item != null) this.subPair = true;
          this.whole[idx] = (item != null ? item : this.whole[idx]);
        })
      }
    },
    loadData() {
      return {// Целая группа
        subPair: false,
        couples_date_id: this.$route.params.day,
        whole: [
          {
            id: null,
            group_discipline_id: '',
            horly_load_id: '',
            cabinet: '',
            pair_type: 0,
            number: this.number,
            analytics: false
          },
          {
            id: null,
            group_discipline_id: '',
            horly_load_id: '',
            cabinet: '',
            pair_type: 1,
            number: this.number,
            analytics: false
          },
          {
            id: null,
            group_discipline_id: '',
            horly_load_id: '',
            cabinet: '',
            pair_type: 2,
            number: this.number,
            analytics: false
          }
        ]
      }
    }
  },
  components: {
    analytics
  }
}
</script>

<style lang="less" scoped>
  .element {
    margin-top: 5px;
    border: thin solid #333;
    border-radius: 10px;
    min-height: 50px;
    display: flex;
    width: 100%;
    .number {
      font-weight: bold;
      font-size: 32px;
      width: 50px;
      background: #333;
      text-align: center;
      color: white;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .pair {
      width: inherit;
      .subPair {
        display: flex;
        justify-content: space-between;
        .sub {
          width: 45%;
          gap: 5px;
        }
      }
      select, input {
        font-size: 14px;
        display: block;
        width: 80%;
        border-radius: 5px;
        border: thin solid #333;
        padding: 5px 10px;
        box-sizing: border-box;
        font-weight: 300;
        margin: 10px auto;
        font-family: Roboto;
      }
      .save {
        width: 100%;
        font-size: 18px;
        font-weight: 300;
        text-align: center;
        line-height: 35px;
        background-color: #45ad5a;
        border-bottom-right-radius: 7px;
        color: white;
        cursor: pointer;
      }
    }
  }
</style>