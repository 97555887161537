<template>
  <div class="auth">

  </div>
</template>



<script>
export default {
  name: "Home"
}
</script>