<template>
  <div class="analytics">
    <div class="head">Внимание, данный педагог уже используйтеся в других расписаниях</div>
    <div class="group" v-for="item in analytics" :key="item">
      <b>Корпус</b>: {{ item.campus }} / <b>Группа</b>: {{ item.group }}
    </div>
  </div>
</template>

<script>
export default {
  name: "analytics",
  props: ['analytics']
}
</script>

<style lang="less" scoped>
  .analytics {
    font-size: 12px;
    background-color: rgb(255 171 171);
    border-radius: 5px;
    width: 90%;
    margin: 10px auto;
    padding: 5px 10px;
    box-sizing: border-box;
    .head {
      font-width: 500;
    }
    .group {
      margin-top: 5px;
    }
  }
</style>