<template>
  <section class="object">
    <div class="menu">
      <a :href="`/year/${$route.params.id}`">Вернуться обратно</a>
      <h2>Группа: <span v-if="teacherAndDisciplines">{{ teacherAndDisciplines.name }}</span></h2>
      <div v-if="teacherAndDisciplines">
        <div class="openTeacher" @click="getItem = item" v-for="item in teacherAndDisciplines.groupDisciplines" :key="item">
          <i class="bi bi-person"></i> {{ item.teacher + ' / ' + item.discipline }}
        </div>
      </div>
    </div>

    <div class="block">
      <show :item="getItem" v-if="getItem"></show>
    </div>
  </section>
</template>

<script>
import {response} from "../response";
import Show from "../components/horlyLoads/Show";

export default {
  name: "HorlyLoads",
  data() {
    return {
      page: "show",
      discipline: null,
      url: 'hourly-load',
      title: 'Нагрузка по часам',
      teacherAndDisciplines: null,
      getItem: null
    }
  },
  async mounted() {
    await this.load();
  },
  components: {
    Show
  },
  methods: {
    async load() {
      let res = await response(this.url + '/' + this.$route.params.hourly);
      if(res.status == 200) this.teacherAndDisciplines = res.json
    }
  }
}
</script>

<style lang="less" scoped>
.menu {
  background-color: white;
  min-height: 300px;
  width: 320px;
  border-radius: 15px;
  box-shadow: 0 2px 9px 0 rgba(0,0,0,0.2);
  box-sizing: border-box;
  padding: 10px 15px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  a {
    color: black;
    font-size: 16px;
    margin-top: 10px;
  }
  .openTeacher {
    font-size: 12px;
    border: thin solid black;
    border-radius: 5px;
    margin-top: 5px;
    padding: 5px 10px;
    cursor: pointer;
  }
}
.block {
  position: relative;
  max-width: 620px;
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 2px 9px 0 rgba(0,0,0,0.2);
  background-color: white;
  min-height: 300px;
  box-sizing: border-box;
  padding: 10px 15px;
}
</style>