<template>
  <div class="auth">
    <h2>Авторизация</h2>
    <input type="text" placeholder="Ваш логин:" v-model="login">
    <input type="password" placeholder="Ваш пароль:"  v-model="password" @keyup.enter="auth">
    <button @click="auth">Авторизоваться</button>
    <hr>
    <p class="small"><a href="#">Eremin Technology | Система расписания</a></p>
    <p class="small">Система составления расписания</p>
  </div>
</template>

<style lang="less" scoped>
.auth {
  margin-top: 100px;
  background: white;
  border-radius: 5px;
  max-width: 450px;
  width: 100%;
  min-height: 300px;
  max-height: 400px;
  box-sizing: border-box;
  padding: 10px 15px;
  box-shadow: 0px 20px 30px 0 rgba(0, 0, 0, 0.4);
  hr {
    border: none;
    border-top: thin solid #78909C;
  }
  h2 {
    font-weight: 600;
    text-align: left;
    width: 80%;
    margin: 20px auto 30px auto;
  }
  input {
    display: block;
    width: 80%;
    font-size: 16px;
    margin: 5px auto;
    border-radius: 5px;
    border: thin solid #78909C;
    padding: 10px 15px;
    box-sizing: border-box;
  }
  button {
    display: block;
    margin: 10px auto;
    width: 250px;
    line-height: 25px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    border: thin solid #546E7A;
    background-color: #546E7A;
    color: white;
    padding: 10px 15px;
    box-sizing: border-box;
  }
  .small {
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    a {
      color: #455A64;
    }
  }
}
</style>

<script>
import { response } from './../response'
export default {
  name: "Login",
  data() {
    return {
      login: "",
      password: ""
    }
  },
  mounted() {
    document.title = 'Авторизация'
    if(localStorage.getItem('token'))
      this.$router.push({name: "Home"})
  },
  methods: {
    async auth() {
      let data = {
        method: 'POST',
        body: JSON.stringify(this.$data)
      }
      let res = await response('login', data);
      if(res.status == 200) {
        localStorage.setItem('token', res.json.token)
        await this.$root.load();
        this.$router.push({name: "Home"})
      }
      else
        console.log(res.json);
    }
  }
}
</script>