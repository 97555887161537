<template>
  <div class="block_destroy">
    <div class="modal">
      <div class="exit" @click="exit">X</div>
      <div class="name">Группа: {{ this.item.name }}</div>
      <div v-if="disciplines">
        <input class="search" v-model="search" placeholder="Поиск преподавателя">
        <div class="teacher" v-for="teacher in filteredList" :key="teacher">
          {{ teacher.name }}
          <div class="discipline"
               :class="{'active': discipline.destroy}"
               v-for="discipline in teacher.disciplines" :key="discipline" @dblclick="createOrDestroy(discipline)">
            {{ discipline.name }}
          </div>
        </div>
      </div>
      <div v-else>Данные загружаются</div>
    </div>
  </div>
</template>

<script>
import {response} from "../../response";

export default {
  name: "Disciplines",
  props: ['item', 'url'],
  data() {
    return {
      search: '',
      disciplines: null
    }
  },
  async mounted() {
    await this.load()
  },
  computed: {
    filteredList() {
      return this.disciplines.teachers.filter(teacher => {
        return teacher.name.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  methods: {
    exit() {
      this.$parent.getItem = null
    },
    async load() {
      let res = await response(`${this.url}/disciplines/${this.item.id}`);
      this.disciplines = res.json
    },
    async createOrDestroy(discipline) {
      let data = {
        method: "POST",
        body: JSON.stringify(discipline)
      }
      let res = await response(`${this.url}/disciplines/${this.item.id}`, data);
      if(res.status == 201)
        this.$root.msg.push({
          status: 'success',
          message: `${discipline.name} успешно добавлен`
        });
      else if(res.status == 202)
        this.$root.msg.push({
          status: 'success',
          message: `${discipline.name} успешно удален`
        });
      else
        this.$root.msg.push({
          status: 'error',
          message: `Ошибка выполнения операции`
        });

      await this.load();
    }
  }
}
</script>

<style lang="less" scoped>
.block_destroy {
  position: absolute;
  border-radius: 15px;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  .modal {
    width: 80%;
    background: white;
    border-radius: 20px;
    min-height: 250px;
    max-height: 80%;
    box-sizing: border-box;
    padding: 10px 20px;
    overflow: auto;
    .search {
      width: 90%;
      box-sizing: border-box;
      margin: 10px auto;
      border-radius: 5px;
      border: thin solid #333;
      font-size: 14px;
      line-height: 35px;
      display: block;
      padding: 3px 15px;
    }
    .teacher {
      font-weight: bold;
      font-size: 16px;
      border: thin solid #455A64;
      border-radius: 5px;
      box-sizing: border-box;
      padding: 5px 10px;
      margin: 5px auto;
      .discipline {
        font-weight: 300;
        font-size: 12px;
        margin: 5px 10px;
        box-sizing: border-box;
        border: thin solid #4b77e4;
        border-radius: 5px;
        padding: 5px 10px;
        &.active {
          background: #4b77e4;
          color: white;
        }
      }
    }

    .exit {
      text-align: center;
      font-size: 18px;
      font-weight: 200;
      cursor: pointer;
      border: thin solid #333333;
      border-radius: 7px;
      width: 30px;
      height: 30px;
      margin: 0 auto;
      line-height: 31px;

      &:hover {
        font-weight: bold;
      }
    }

    .name {
      margin-top: 20px;
      font-size: 22px;
      font-weight: bold;
      text-align: center;
    }

    .small {
      font-size: 14px;
    }

    .destroy {
      border-radius: 7px;
      line-height: 31px;
      padding: 0 10px;
      background-color: #eb4444;
      color: white;
      width: 150px;
      margin: 10px auto;
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>