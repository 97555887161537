<template>
  <div>
    <h2>{{ $parent.title }} | Создание</h2>
    <div class="success" v-if="status == 201">{{ $parent.title }} | Успешно создан</div>
    <div class="errors" v-if="status == 422">{{ errors.name[0] }}</div>
    <input type="text" v-model="name" placeholder="Введите тип урока">
    <label class="checkbox" for="consider">
      <input type="checkbox" v-model="consider" id="consider">Данный тип урока нужно учитывать
    </label>
    <p class="small">Данная функция позволяет учитывать данный тип в сетке расписания. При снятии галочки, при составлении расписании не будет учитываться данные пары, ошибка не будет выдаваться по данному типу урока, если они имеют за двоение.</p>
    <label class="checkbox" for="off_grid"><input type="checkbox" id="off_grid" v-model="off_grid"> Вне сетки</label>
    <button @click="create()">Создать</button>
  </div>
</template>

<script>
import {response} from "./../../response"
export default {
  name: "Create",
  data() {
    return {
      name: '',
      consider: true,
      off_grid: false,
      errors: null,
      status: -1
    }
  },
  methods: {
    async create() {
      let data = {
        method: "POST",
        body: JSON.stringify(this.$data)
      }
      let res = await response(`${this.$parent.url}/create`, data);
      this.status = res.status;
      if(res.status != 201) this.errors = res.json.errors
      else this.name = ''
    }
  }
}
</script>

<style scoped>
  .errors {
    color: #eb4444;
    text-align: center;
    font-weight: 400;
    margin: 5px 0;
  }
  .success {
    color: green;
    font-weight: 500;
    margin: 10px 0;
    text-align: center;
  }
  .small {
    font-size: 12px;
    margin: 10px auto;
    width: 60%;
    padding: 0;
  }
  .checkbox {
    width: 60%;
    margin: 5px auto;
    display: block;
    padding: 5px 10px;
    border-radius: 7px;
    border: thin solid #606060;
    font-size: 16px;
  }
  input[type=text] {
    width: 60%;
    margin: 0 auto;
    display: block;
    padding: 5px 10px;
    border-radius: 7px;
    border: thin solid #606060;
    font-size: 16px;
  }
  button {
    width: 200px;
    display: block;
    margin: 10px auto;
    border: none;
    padding: 10px 15px;
    border-radius: 10px;
    background-color: #4b77e4;
    font-weight: bold;
    color: white;
    font-size: 16px;
  }
</style>