<template>
  <div class="block_destroy">
    <div class="modal">
      <div class="exit" @click="exit">X</div>
      <div class="name">{{ item.name }}</div>
      <div v-if="status">
        <p class="small">Если вы точно хотите удалить, кликните дважды по кнопке удалить</p>
        <div class="destroy" @dblclick="destroy()">Удалить</div>
      </div>
      <div v-else>Данный аккаунт является с полными правами, удаление запрещено!</div>
    </div>
  </div>
</template>

<script>
import {response} from "../../response";
export default {
  name: "Destroy",
  data() {
    return {
      status: null
    }
  },
  props: ['item', 'url'],
  async mounted() {
    let res = await response(`${this.url}/destroy/${this.item.id}`);
    if(res.status == 200)
      this.status = res.json.status
  },
  methods: {
    async exit() {
      this.$parent.getDestroy = null;
      await this.$parent.load();
    },
    async destroy() {
      let data = {
        method: 'DELETE'
      };
      let res = await response(`${this.url}/destroy/${this.item.id}`, data);
      if(res.status == 202)
        this.$root.msg.push({
          status: 'success',
          message: `Удаление объекта ${this.item.name} выполнено успешно`
        });
      else
        this.$root.msg.push({
          status: 'error',
          message: `Ошибка выполнения операции`
        });
      this.exit();
    }
  }
}
</script>

<style lang="less" scoped>
  .block_destroy {
    position: absolute;
    border-radius: 15px;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    .modal {
      width: 80%;
      background: white;
      border-radius: 20px;
      min-height: 250px;
      box-sizing: border-box;
      padding: 10px 20px;
      .exit {
        text-align: center;
        font-size: 18px;
        font-weight: 200;
        cursor: pointer;
        border: thin solid #333333;
        border-radius: 7px;
        width: 30px;
        height: 30px;
        margin: 0 auto;
        line-height: 31px;
        &:hover {
          font-weight: bold;
        }
      }
      .name {
        margin-top: 20px;
        font-size: 22px;
        font-weight: bold;
        text-align: center;
      }
      .small {
        font-size: 14px;
      }
      .destroy {
        border-radius: 7px;
        line-height: 31px;
        padding: 0 10px;
        background-color: #eb4444;
        color: white;
        width: 150px;
        margin: 10px auto;
        text-align: center;
        cursor: pointer;
      }
    }
  }
</style>