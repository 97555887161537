<template>
  <div></div>
</template>

<script>
import {response} from "../response";
export default {
  name: "Logout",
  async mounted() {
    await response('logout');
    localStorage.clear()
    return this.$router.push({name: "Login"})
  }
}
</script>

<style scoped>

</style>