<template>
  <div class="timetable">
    <div class="print" @click="print()"><i class="bi bi-printer"></i> Распечатать или сохранить</div>
    <div class="statement">Утверждаю Зам. по УР<br>_________________ С.Г. Панова</div>
    <h2>Расписание на {{ date }}</h2>
    <div class="table">
      <div class="schedule" v-for="item in couplesDate.couplesDate.academic_year.groups" :key="item">
        <div class="name">{{ item.name }}</div>
        <scheduleItem :number="i" :group="item" :schedules="couplesDate.schedules" v-for="i in 8" :key="i"></scheduleItem>
      </div>
    </div>
  </div>
</template>

<script>
import scheduleItem from "./scheduleItem";
export default {
  name: "scheduleStudent",
  props: ['couplesDate'],
  computed: {
    date() {
      let date = new Date(Date.parse(this.couplesDate.couplesDate.date))
      return date.getDate() + " " + this.month(date.getMonth()) + " " + date.getFullYear() + " (" + this.nameDay(date.getDay()) + ")"
    }
  },
  methods: {
    print() {
      print()
    },
    month(month) {
      let name = ''
      switch(month) {
        case 0: name = "январь"; break;
        case 1: name = "февраль"; break;
        case 2: name = "март"; break;
        case 3: name = "апрель"; break;
        case 4: name = "май"; break;
        case 5: name = "июнь"; break;
        case 6: name = "июль"; break;
        case 7: name = "август"; break;
        case 8: name = "сентябрь"; break;
        case 9: name = "октябрь"; break;
        case 10: name = "ноябрь"; break;
        case 11: name = "декабрь"; break;
      }
      return name
    },
    nameDay(day) {
      let name = ''
      switch(day) {
        case 0: name = "Воскресенье"; break;
        case 1: name = "Понедельник"; break;
        case 2: name = "Вторник"; break;
        case 3: name = "Среда"; break;
        case 4: name = "Четверг"; break;
        case 5: name = "Пятница"; break;
        case 6: name = "Суббота"; break;
      }
      return name
    }
  },
  components: {
    scheduleItem
  }
}
</script>

<style lang="less" scoped>
.timetable {
  font-family: 'Times New Roman';
  .print {
    border: thin solid #333;
    font-family: Roboto;
    width: 200px;
    padding: 5px 10px;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 12px;
    text-align: center;
  }
  @media print {
    .print {
      display: none;
    }
  }
  .statement {
    text-align: right;
    box-sizing: border-box;
    padding: 10px 40px;
  }

  h2 {
    text-align: center;
  }

  .table {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    .schedule {
      width: 100px;
      font-size: 8px;
      border: thin solid #333;

      .name {
        font-size: 12px;
        text-align: center;
        font-weight: bold;
        border-bottom: thin solid #333;
        padding: 2px 5px;
      }
    }
  }
}
</style>