export async function response(host, data = {}) {
    data = {
        bearer: localStorage.getItem('token'),
        method: 'GET',
        body: null,
        headers: {},
        ...data
    };
    document.getElementById('download').style.display = "block";
    let res = await fetch(`https://api.collegett.ru/api/${host}`, {
        method: data.method,
        headers: {
            'Content-Type' : 'application/json',
            'Accept' : 'application/json',
            'Authorization': 'Bearer ' + data.bearer,
            ...data.headers
        },
        body: data.body
    })
    let resJson = await res.json()
    document.getElementById('download').style.display = "none";
    return {status: res.status, json: resJson}
}

