<template>
  <section class="object">
    <div class="menu">
      <a :href="`/timetables/${$route.params.year}`">Вернуться обратно</a>
      <a class='txt-decoration-none' @click="getSchedule('scheduleTeacher')"><i class="bi bi-calendar3"></i> Преподаватели</a>
      <a class='txt-decoration-none' @click="getSchedule()"><i class="bi bi-calendar3"></i> Студенты</a>
      <h2>Группы</h2>
      <div class="groups" v-if="getData">
        <div class="group" :class="{active: (getSelect == item)}" v-for="item in getData" :key="item" @click="openSchedule(item)">{{ item.name }}</div>
      </div>
      <div v-else>Ожидайте загрузку данных</div>
    </div>

    <div class="block">
      <itemSchedule v-if="getSelect" :item="getSelect" ></itemSchedule>
      <schedule-student v-if="opSchedule === 'schedule'" :couples-date="osSystem"></schedule-student>
      <schedule-teacher v-if="opSchedule === 'scheduleTeacher'" :couples-date="osSystem"></schedule-teacher>
    </div>
  </section>
</template>

<script>
import {response} from "../response";
import itemSchedule from "../components/schedule/itemSchedule";
import scheduleStudent from "../components/schedule/scheduleStudent";
import scheduleTeacher from "../components/schedule/scheduleTeacher";


export default {
  name: "Schedule",
  data() {
    return {
      page: "show",
      url: 'timetables',
      title: 'Учебные корпуса',
      getData: null,
      getSelect: null,
      osSystem: null,
      opSchedule: null
    }
  },
  async mounted() {
    let res = await response(`timetables/${this.$route.params.day}/groups`);
    if(res.status == 200) {
      this.getData = res.json
      this.$root.addMSG('Группы подгружены', 'success')
    }
    else this.$root.addMSG('Ошибка загрузка данных', 'error')
  },
  methods: {
    openSchedule(item) {
      this.getSelect = null
      this.osSystem = null
      this.opSchedule = null
      this.$root.print = null
      this.getSelect = item
    },
    async getSchedule(open = 'schedule') {
      this.getSelect = null
      let res = await response(`timetables/${this.$route.params.day}/${open}`)
      if(res.status == 200) this.osSystem = res.json
      this.opSchedule = open
    }
  },
  components: {
    itemSchedule,
    scheduleStudent,
    scheduleTeacher
  }
}
</script>

<style lang="less" scoped>
@media screen {
  .menu {
    background-color: white;
    min-height: 300px;
    width: 220px;
    border-radius: 15px;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    padding: 10px 15px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    .groups {
      max-height: 350px;
      overflow: auto;
      padding: 5px 0;

      .group {
        color: black;
        font-size: 16px;
        margin-top: 5px;
        border: thin solid #333;
        border-radius: 5px;
        padding: 5px 10px;
        box-sizing: border-box;

        &.active {
          background: #4b77e4;
          color: white;
        }
      }
    }

    a {
      color: black;
      font-size: 16px;
      margin-top: 10px;

      &.txt-decoration-none {
        text-decoration: none;
      }
    }
  }

  .block {
    position: relative;
    max-width: 720px;
    width: 100%;
    border-radius: 15px;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.2);
    background-color: white;
    min-height: 300px;
    box-sizing: border-box;
    padding: 10px 15px;
  }

  hr {
    width: 100%;
    border: none;
    border-bottom: thin solid #ccc;
    margin-top: 20px;
    display: block;
  }
}
@media print {
  .menu {
    display: none;
  }
}
</style>