import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Teachers from "../views/Teachers";
import Disciplines from "../views/Disciplines";
import Campus from "../views/Campus";
import Users from "../views/Users";
import LessonType from "../views/LessonType";
import Years from "../views/Years";
import AcademicYears from "../views/AcademicYears";
import Groups from "../views/Groups";
import HorlyLoads from "../views/HorlyLoads";
import Logout from "../views/Logout";
import Timetables from "../views/Timetables";
import CouplesDates from "../views/CouplesDates";
import Schedule from "../views/Schedule";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/teachers',
    name: 'Teachers',
    component: Teachers
  },
  {
    path: '/disciplines',
    name: 'Disciplines',
    component: Disciplines
  },
  {
    path: '/campus',
    name: 'Campus',
    component: Campus
  },
  {
    path: '/campus/:id',
    name: 'AcademicYears',
    component: AcademicYears
  },
  {
    path: '/year/:id',
    name: 'Groups',
    component: Groups
  },
  {
    path: '/users',
    name: 'Users',
    component: Users
  },
  {
    path: '/lesson-type',
    name: 'LessonType',
    component: LessonType
  },
  {
    path: '/year/:id/horly-loads/:hourly',
    name: 'HorlyLoads',
    component: HorlyLoads
  },
  {
    path: '/years',
    name: 'Years',
    component: Years
  },
    // Time table
  {
    path: '/timetables',
    name: 'Timetables',
    component: Timetables
  },
  {
    path: '/timetables/:year',
    name: 'CouplesDates',
    component: CouplesDates
  },
  {
    path: '/timetables/:year/couples-date/:day',
    name: 'Schedule',
    component: Schedule
  },
    // Time Table
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
