<template>
  <h1>{{ $parent.title }} | Весь список</h1>
  <div class="block_get_data">
    <div class="block_item" v-for="item in getData" :key="item.id">
      <div class="name">
        <div>{{ item.name }}</div>
      </div>
      <div class="operation">
        <div class="destroy" @click="getDestroy = item"><i class="bi bi-trash"></i> Удалить</div>
      </div>
    </div>
  </div>
  <Destroy :item="getDestroy" :url="$parent.url" v-if="getDestroy"></Destroy>
</template>

<script>
import {response} from "./../../response"
import Destroy from "./Destroy";
export default {
  name: "Show",
  components: {
    Destroy
  },
  data() {
    return {
      getData: {},
      getDestroy: null
    }
  },
  async mounted() {
    await this.load();
  },
  methods: {
    async load() {
      let res = await response(this.$parent.url);
      if(res.status == 403)
        return this.$router.push({name: "Home"})
      this.getData = res.json
    }
  }
}
</script>

<style lang="less" scoped>
.block_get_data {
  position: relative;
  max-height: 450px;
  overflow: auto;
  .block_item {
    display: flex;
    justify-content: space-between;
    border: thin solid #78909c;
    border-radius: 6px;
    margin-top: 5px;
    box-sizing: border-box;
    padding: 5px 10px;
    align-items: center;

    .name {
      max-width: 35%;
      min-width: 35%;
      width: 100%;
      color: black;
      font-size: 16px;
      font-weight: 300;
    }

    .off_grid, .consider {
      background: #333333;
      color: white;
      font-weight: bold;
      padding: 5px 10px;
      border-radius: 4px;
      font-size: 12px;
      cursor: pointer;
    }
  }
}
</style>