<template>
  <h1>{{ $parent.title }} | Весь список</h1>
  <div class="block_get_data" v-if="getData">
    <div class="block_item" v-for="item in getData" :key="item">
      <div class="name" @dblclick="editUser = item">{{ item.name }}</div>
      <div class="access">
        <div class="role">{{ item.role }}</div>
        <div class="campus" v-if="item.access != 'creator'">{{ item.campus.name }}</div>
      </div>
      <div class="operation" v-if="item.access != 'creator'">
        <div class="destroy" @click="getDestroy = item">Удалить</div>
      </div>
    </div>
  </div>
  <Destroy :item="getDestroy" :url="$parent.url" v-if="getDestroy"></Destroy>
  <Edit :item="editUser" :url="$parent.url" v-if="editUser"></Edit>
</template>

<script>
import {response} from "./../../response"
import Destroy from "./Destroy";
import Edit from "./Edit";
export default {
  name: "Show",
  components: {
    Destroy,
    Edit
  },
  data() {
    return {
      getData: null,
      getDestroy: null,
      editUser: null,
    }
  },
  async mounted() {
    await this.load();
  },
  methods: {
    async editSend(item) {
      let data = {
        method: "POST",
        body: JSON.stringify(item)
      }

      let res = await response(`${this.$parent.url}/edit/${item.id}`, data)
      item.edit = false;
      if(res.status === 202) alert('Операция успешно выполнена!');
    },
    async load() {
      let res = await response(this.$parent.url);
      if(res.status === 403)
        return this.$router.push({name: "Home"})
      this.getData = res.json
    }
  }
}
</script>

<style lang="less" scoped>
.block_get_data {
  position: relative;
  max-height: 450px;
  overflow: auto;
  .block_item {
    display: flex;
    justify-content: space-between;
    border: thin solid #78909c;
    border-radius: 6px;
    margin-top: 5px;
    box-sizing: border-box;
    padding: 5px 10px;
    align-items: center;

    .name {
      color: black;
      font-size: 16px;
      font-weight: 300;
    }

    .access {
      display: flex;
      gap: 2px;
      cursor: default;
      .role {
        font-size: 12px;
        font-weight: bold;
        background-color: #235ff5;
        color: white;
        padding: 5px 10px;
        border-radius: 4px;
      }
      .campus {
        font-size: 12px;
        font-weight: bold;
        background-color: #4bd;
        color: white;
        padding: 5px 10px;
        border-radius: 4px;
      }
    }

    .operation {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .edit, .destroy {
        text-decoration: underline;
        font-size: 12px;
        cursor: pointer;
        line-height: 20px;
        border: thin solid #333333;
        padding: 2px 5px;
        border-radius: 3px;
        text-align: center;
      }

      .edit {
        background-color: yellow;
      }

      .destroy {
        background-color: #ff9393;
      }
    }
  }
}
</style>